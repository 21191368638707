import React from 'react';
import PropTypes from 'prop-types';
import Button from '../buttons/importLogoButton.js';
import QrCode from '../../assets/images/qrCode.png';
import QrCodeWhite from '../../assets/images/qrCodeWhite.png';

import '../components.css';

const BackCardContent = (props) => {
  return (
    props.cardOrientation === 'horizontal' ?
      <div style={{display: 'flex', flexDirection: 'row'}}>
        {/* Back card half left component*/}
        <div style={{position: "relative", width: props.width * 0.49, height: props.height, alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
          <div style={{height: '50%', width: "100%", position: "absolute", top: 0, left: 11}}>
          {
            props.logoFile !== ''
            ? <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', position: "relative", paddingTop: "100px"}}>
                <div style={{position: "absolute"}}>
                  <img src={props.logoFile} alt='backLogoImage' style={{width: props.backLogoWidth}}/>
                </div>
              </div>
            : null
          }
          </div>
          <div style={{height: '50%', width: "100%", position: "absolute", bottom: 0, left: 11}}>
            <p style={{fontFamily: "Axiforma", fontWeight: "bold", fontSize: 20, height: 45, paddingLeft: 10, paddingRight: 10, marginTop: props.getMarginForBackCard('fullName')}} className={props.alignText === "Gauche" ? "textAreaLeft" : props.alignText === "Centré" ? "textAreaCenter" : "textAreaRight"}>
              <span style={props.selectedCardBaseColor === "black" ? {color: "white"} : {color: "black"}}>{!props.isCommandMultiple ? props.cardFullName : "John Doe"}</span>
            </p>
            <p style={{fontFamily: "Axiforma", fontSize: 16, height: 45, paddingLeft: 10, paddingRight: 10, marginTop: props.getMarginForBackCard('jobTitle')}} className={props.alignText === "Gauche" ? "textAreaLeft" : props.alignText === "Centré" ? "textAreaCenter" : "textAreaRight"}>
              <span style={props.selectedCardBaseColor === "black" ? {color: "white"} : {color: "black"}}>{!props.isCommandMultiple ? props.cardJobTitle : "Responsable commercial"}</span>
            </p>
            <p style={{fontSize: 20, height: 45, paddingLeft: 10, paddingRight: 10, marginTop: props.getMarginForBackCard('phoneNumber')}} className={props.alignText === "Gauche" ? "textAreaLeft" : props.alignText === "Centré" ? "textAreaCenter" : "textAreaRight"}>
              <span style={props.selectedCardBaseColor === "black" ? {color: "white", fontFamily: "Axiforma", fontWeight: "bold"} : {color: "black", fontFamily: "Axiforma", fontWeight: "bold"}}>{!props.isCommandMultiple ? props.cardPhoneNumber : "0600000000"}</span>
            </p>
          </div>
        </div>
        {/* Back card half right component*/}
        <div style={{width: props.width * 0.49, height: 'auto', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
          { props.selectedCardBaseColor === "black" ? <img src={QrCodeWhite} alt='QrCodeImage' style={{width: '80%'}}/> : <img src={QrCode} alt='QrCodeImage' style={{width: '80%'}}/>}
        </div>
      </div>
    :
    <div style={{width: 325, display: 'flex', flexDirection: 'column'}}>
      <div style={{height: props.height * 0.50,}}>
        {
          props.logoFile !== ''
          ? <div style={{position: 'relative', justifyContent: 'center', alignItems: 'center', display: 'flex', marginBottom: '3em'}}>
              <div style={{position: 'absolute', top: 0}}>
                <img src={props.logoFile} alt='backLogoImage' style={{width: props.backLogoWidth}}/>
              </div>
            </div>
          : null
        }
        <div className='verticalCenter' style={{marginTop: -30}}>
          <p style={{fontFamily: "Axiforma", fontWeight: "bold", fontSize: 20, height: 45, paddingLeft: 10, paddingRight: 10, marginTop: props.getMarginForBackCard('fullName')}} className={props.alignText === "Gauche" ? "textAreaLeft" : props.alignText === "Centré" ? "textAreaCenter" : "textAreaRight"}>
            <span style={props.selectedCardBaseColor === "black" ? {color: "white"} : {color: "black"}}>{props.cardFullName}</span>
          </p>
          <p style={{fontFamily: "Axiforma", fontSize: 20, height: 45, paddingLeft: 10, paddingRight: 10, marginTop: props.getMarginForBackCard('jobTitle')}} className={props.alignText === "Gauche" ? "textAreaLeft" : props.alignText === "Centré" ? "textAreaCenter" : "textAreaRight"}>
            <span style={props.selectedCardBaseColor === "black" ? {color: "white"} : {color: "black"}}>{props.cardJobTitle}</span>
          </p>
          <p style={{fontFamily: "Axiforma", fontWeight: "bold", fontSize: 20, height: 45, paddingLeft: 10, paddingRight: 10, marginTop: props.getMarginForBackCard('phoneNumber')}} className={props.alignText === "Gauche" ? "textAreaLeft" : props.alignText === "Centré" ? "textAreaCenter" : "textAreaRight"}>
            <span style={props.selectedCardBaseColor === "black" ? {color: "white"} : {color: "black"}}>{props.cardPhoneNumber}</span>
          </p>
        </div>
      </div>
      {/* Back card half right component*/}
      <div style={{marginTop: 40, textAlign: 'center'}}>
      { props.selectedCardBaseColor === "black" ? <img src={QrCodeWhite} alt='QrCodeImage' style={{width: '50%'}}/> : <img src={QrCode} alt='QrCodeImage' style={{width: '50%'}}/>}
      </div>
    </div>
  );
}
export default BackCardContent;

BackCardContent.propTypes = {

};

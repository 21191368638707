import React from 'react';
import ReactDOM from 'react-dom';
import * as Services from '../services/apiServices.js';
import * as Security from '../services/securityServices.js';
import * as loader from '../assets/animations/loader.json';
import Lottie from 'react-lottie';
import Register from './register.js';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import SimpleButton from '../components/buttons/loginButtonNew.js';
import TextButton from '../components/buttons/textButton.js';
import Orders from './orders.js';
import Modal from '../components/modals/resetPasswordModalWithEmail';
import SnackBar from '../components/snackbars/snackbar.js';
import {withRouter} from "react-router-dom";
import './screens.css';
import {IoArrowBack} from "react-icons/io5";

//option used for spinning loader
const lottieSpinnerOption = {
  loop: true,
  autoplay: true,
  animationData: loader.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      isResetPasswordModalVisible: false,
      isSnackbarVisible: false,
      snackbarSeverity: '',
      snackbarMessage: '',
      isLoaderVisible: false,
      accountLinkToken: '',
    };
  }

  componentDidMount() {
    this.setState({accountLinkToken: this.props.match.params.id}, function() {
      //console.log(this.state.accountLinkToken);
    });
  }

  //called when login button clicked
  _loginButtonClicked = async () => {
    //check if fields are filled
    if (!this._checkForEmptyFields()) {
      this.setState({snackbarSeverity: 'error'});
      this.setState({snackbarMessage: "Merci de remplir l'ensemble des champs"});
      this._handleSnackbarState();
      return;
    }
    //check if email format is ok
    if (!Security.isEmailFormatOk(this.state.email)) {
      this.setState({snackbarSeverity: 'error'});
      this.setState({snackbarMessage: "Merci de renseigner un email valide"});
      this._handleSnackbarState();
      return;
    }
    const data = {
      username: this.state.email,
      password: this.state.password,
      accountLinkToken: this.state.accountLinkToken
    };
    this.setState({isLoaderVisible: true});
    let res = await Services.login(JSON.stringify(data));
    if (!res.success) {
      this.setState({snackbarSeverity: 'error'});
      this.setState({snackbarMessage: "Identifiants invalides"});
      this.setState({isLoaderVisible: false})
      this._handleSnackbarState();
    } else {
      localStorage.setItem("isUserConnected", "true");
      localStorage.setItem("token", res.token);
      localStorage.setItem("account", JSON.stringify(res.account));
      this.props.history.push('/mes_commandes');
    }


    /*const loginSuccess = await Services.login(JSON.stringify(data));
    if (loginSuccess) {
      alert('OK');
      ReactDOM.render(<Orders />, document.getElementById('root'));
    }
    else {
      alert('KO');
    }
    this.setState({isLoaderVisible: false});*/
  }

  //called when register button is clicked
  _registerButtonClicked = () => {
    //display register page
    if (this.state.accountLinkToken !== '') {
      this.props.history.push('/inscription/' + this.state.accountLinkToken)
    }
    else (
      this.props.history.push('/inscription')
    )
  }

  //called when forgot password button clicked
  _forgotPasswordButtonClicked = () => {
    this._handleResetPasswordModalState();
  }

  //handle textfields change
  _handleInputChange = (stateName, e) => {
    this.setState({[stateName]: e.target.value});
  }

  //display forgot password modal
  _handleResetPasswordModalState = () => {
    const currentState = this.state.isResetPasswordModalVisible;
    this.setState({isResetPasswordModalVisible: !currentState});
  }

  //used to check empty fields
  _checkForEmptyFields = () => {
    if (this.state.email === '' || this.state.password === '') {
      return false;
    }
    return true;
  }

  //function called to open or close snackbar
  _handleSnackbarState = () => {
    const currentState = this.state.isSnackbarVisible;
    this.setState({isSnackbarVisible: !currentState});
  }

  render() {
    return (
      <div>
        <div style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#07393D'
        }}>
          {/*Forgot password modal*/}
          <Modal isResetPasswordModalVisible={this.state.isResetPasswordModalVisible}
                 handleResetPasswordModalState={this._handleResetPasswordModalState}/>
          <div className="c-formulaire_field_outlined">
            <Container
              maxWidth='sm'
              className='container'
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                borderRadius: "15px",
                backgroundColor: 'white'
              }}
            >

              <div style={{
                width: "inherit",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: "column",
                position: 'relative'
              }}>
                <div style={{position: "absolute", left: -10, top: 10, color: '#003732', cursor: 'pointer', height: 60, width: 60,
                  background: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%'}} onClick={() => window.history.back()}>
                  <IoArrowBack size={40}/>
                </div>
                <img src="https://www.wemet.fr/img/contenu/wemet/presentation-wecard.jpg" alt=""
                     style={{width: "8em", height: "auto", marginTop: "-3em", borderRadius: '50%'}}/>
                {/*Screen title*/}
                <h1 id='appTitle' style={{color: "#003737", textAlign: 'center'}}>Connexion </h1>
                {/*Login form*/}
                <form style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '80%',
                  minWidth: '20em',
                  marginBottom: '1em'
                }}>
                  {/*Email textfield*/}
                  <TextField onChange={(e) => this._handleInputChange('email', e)}
                             label='Email*' variant="outlined" />
                  {/*Password textfield*/}
                  <TextField type='password'
                             onChange={(e) => this._handleInputChange('password', e)} label='Mot de passe*' variant="outlined" />
                </form>
                {/*Buttons div*/}
                <div
                  style={{display: 'flex', flexDirection: 'column', width: '50%', minWidth: '10em', maxWidth: '15em'}}>
                  {/*Login button*/}
                  <SimpleButton onClick={this._loginButtonClicked} isColorPrimary={true} buttonName='Connexion'/>
                  <span style={{marginBottom: "0.5em"}}/>
                  {/*Register button*/}
                  <SimpleButton onClick={this._registerButtonClicked} isColorPrimary={false}
                                buttonName='Créer un compte'/>
                  <span style={{marginBottom: "0.5em"}}/>
                  {/*Forgot password button*/}
                  <div onClick={this._forgotPasswordButtonClicked} className="pointer" style={{width: "100%", textAlign: 'center', marginBottom: 20}}>
                    <span className="c-article__link">Mot de passe oublié ?</span>
                  </div>
                </div>
                {
                  //alert snackbar
                  this.state.isSnackbarVisible ?
                    <SnackBar handleClose={this._handleSnackbarState} severity={this.state.snackbarSeverity}
                              message={this.state.snackbarMessage}/> : null
                }
                {
                  //spinning loader
                  this.state.isLoaderVisible ?
                    <div className='loader'><Lottie options={lottieSpinnerOption} isClickToPauseDisabled={true}/>
                    </div> : null
                }
              </div>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Login);

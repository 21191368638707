import React from 'react';
import AppBar from '../components/appBar/homeAppBar.js';
import {withRouter} from "react-router-dom";
import './screens.css';
import Footer from "../components/footer/footer";
import * as Services from '../services/apiServices.js';
import {loadStripe} from "@stripe/stripe-js";

class PayOrder extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      order: null,
      loaded: false
    }
  }

  componentDidMount() {
    const isUserConnected = localStorage.getItem("isUserConnected");
    if (isUserConnected === "false") {
      this.props.history.push('/connexion');
    } else {
      this._getOrder(this.props.match.params.orderId);
    }
  }

  _getOrder = async (orderId) => {
    const order = await Services.getOrder(orderId);
    if (order && order.id) {
      this.setState({order: order});
    }
    this.setState({loaded: true});
  }

  _getOrderPay = async (orderId) => {
    const paiement = await Services.getOrderPay(orderId);
    if (paiement.success && paiement.sessionCheckout) {
      const stripe = await loadStripe(paiement.stripe_pk);
      return stripe.redirectToCheckout({sessionId: paiement.sessionCheckout});
    }
  }

  render() {
    return (
      <div>
        <div className="marginAuto" style={{marginTop: 150, width: '100%', textAlign: 'center'}}>
          {this.state.order && !this.state.order.paid ? (
            <>
              <h1 style={{color: "#003939", marginBottom: 50, textAlign: 'center'} }>Paiement de votre commande {this.state.order.referenceCode}</h1>
              <h3 style={{color: "#003939", marginBottom: 50, textAlign: 'center'} }>Le montant à payer est de {this.state.order.totalTtc}€</h3>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                <div className="hoverButton pointer" style={{width: 200}} onClick={() => this._getOrderPay(this.state.order.id)}>
                  <p className="hoverButtonLoginText">Payer ma commande</p>
                </div>
              </div>
            </>
            ) : (
            <>
              {this.state.loaded && (
                <h1 style={{color: "#003939", marginBottom: 50, textAlign: 'center'} }>Une erreur est survenue lors de la récupération de votre commande...</h1>
              )}
            </>
          )}
        </div>
        <AppBar/>
        <Footer/>
      </div>
    )
  }
}

export default withRouter(PayOrder);

import React from 'react';
import Footer from "../components/footer/footer";
import AppBar from '../components/appBar/homeAppBar.js';
import * as Services from '../services/apiServices.js';
import Button from '@material-ui/core/Button';
import {withRouter} from "react-router-dom";
import './screens.css';

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

class UploadMyFiles extends React.Component {
  constructor(props) {
    super(props)
    this.inputFile = React.createRef();
    this.state = {
      id: "",
      selectedFileName: "Aucun fichier selectionné",
      selectedFile: null,
      selectedFileExt: ''
    }
  }

  componentDidMount() {
    this.setState({id: this.props.match.params.id});
  }

  _handleFileUpload = (e) => {
    const { files } = e.target;
    if (files && files.length) {
      const file = (window.URL || window.webkitURL).createObjectURL(files[0]);
      const filename = files[0].name;
      const parts = filename.split(".");
      const fileType = parts[parts.length - 1];
      this.setState({selectedFileExt: fileType});
      this.setState({selectedFile: files[0]}, function() {
        //console.log(this.state.selectedFile)
      });
      this.setState({selectedFileName: filename});
    }
  };

   _selectFile = () => {
    this.inputFile.current.click();
  };

  _toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  _sendFile = async () => {
   if (this.state.selectedFile == null) {
     alert('Merci de choisir un fichier à envoyer');
     return;
   }
   const file = await toBase64(this.state.selectedFile);
   //console.log(file)
   const data = {
     "id": this.state.id,
     "fileName": this.state.selectedFileName,
     "fileExt": this.state.selectedFileExt,
     "file": file
   }
   const ret = await Services.sendFileFromQrcode(JSON.stringify(data));
   //console.log(ret)
   if (ret.success) {
     alert('Votre fichier a bien été envoyé à notre serveur');
     this._resetState();
   }
   else {
     alert("Une erreur est survenue lors de l'envoie de votre fichier");
   }
 };

 _resetState = () => {
   this.setState({selectedFile: null});
   this.setState({selectedFileExt: ""});
   this.setState({selectedFileName: "Aucun fichier selectionné"});
 }

  render() {
    return (
      <div>
        <AppBar/>
          <div style={{marginTop: 170, paddingLeft: 10}}>
            <h2 style={{marginBottom: 50}}>Choisir le fichier à envoyer</h2>
            <p style={{textDecoration: "underline"}}>Fichier sélectionné : </p>
            <p>{this.state.selectedFileName}</p>

            <div>
              <input
                style={{ display: 'none' }}
                ref={this.inputFile}
                onChange={(e) => this._handleFileUpload(e)}
                type='file'
              />
              <div style={{display: "flex", justifyContent: "center"}}>
                <Button
                  variant="contained"
                  onClick={() => this._selectFile()}
                  style={{backgroundColor: '#003732', height: 40, marginTop: 30, width: "40%", marginRight: 10}}
                >
                  <span style={{color: 'white'}}>Choisir</span>
                </Button>
                <Button
                  variant="contained"
                  onClick={() => this._sendFile()}
                  style={{backgroundColor: '#003732', height: 40, marginTop: 30, width: "40%", marginRight: 10}}
                >
                  <span style={{color: 'white'}}>Envoyer</span>
                </Button>
              </div>
            </div>
          </div>
      </div>
    )
  }
}

export default withRouter(UploadMyFiles);

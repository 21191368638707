import React from 'react';
import Button from '@material-ui/core/Button';
import CardTypeGrid from './cardTypeComponent.js';
import CardTextureComponent from './cardTextureComponent.js';
import CardColorComponent from './cardColorComponent.js';
import ImportCardLogo from '../fileExplorer/importLogoFileExplorer.js';
import BottomButtons from '../buttons/newOrderBottomButtons.js';
import BottomButtonsSingle from '../buttons/newOrderSingleBottomButtons.js';
import CardLogoSizeSlider from '../sliders/cardLogoSizeSlider.js';
import CardLogoSizeSliderBack from '../sliders/cardLogoSizeSliderForBack.js';
import TypoComponent from './typoComponent.js';
import InformationComponent from './informationComponent.js';
import ContactComponent from './contactComponent.js';
import Validation from './validation.js';
import ImportPictureButton from '../fileExplorer/importUserProfilePicture.js';
import '../components.css';
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

const Step0 = (props) => {
  return (
    props.isCommandMultiple && (localStorage.getItem('isUserConnected') == "false" || !localStorage.getItem('token')) ?
    (<div style={{
      width: "80%", height: 150, alignSelf: 'start',
      marginTop: 130
    }}>
      <p>Pour débuter la commande, vous devez être connecté</p>
      <div onClick={props.requestLogin} className="hoverButton2 pointer" style={{width: 300}}>
        <span className="hoverButtonText" style={{width: 300}}>Connexion</span>
      </div>
    </div>)
    :
    (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div style={{display: 'flex', flexDirection: 'column', margin: 'auto', width: '100%'}}>
        <ContactComponent {...props} />
      </div>
      <BottomButtonsSingle {...props} />
    </div>
    )
  );
}

const Step1 = (props) => {
  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div style={{display: 'flex', flexDirection: 'column', margin: 'auto', alignItems: 'center', marginBottom: '60px'}}>
        <h2>Orientation de la carte</h2>
        <div style={{display: "flex", marginLeft: "auto", marginRight: "auto"}}>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', margin: 'auto', alignItems: 'center'}}>
          <div onClick={() => props.changeCardOrientation('vertical')} className="pointer" style={{marginRight: 10}}>
            <span className={props.isCardHorizontal ? "c-article__link noAfter" : "c-article__link"}>Mode portrait</span>
          </div>
          <div onClick={() => props.changeCardOrientation('horizontal')} className="pointer" style={{marginLeft: 10}}>
            <span className={props.isCardVertical ? "c-article__link noAfter" : "c-article__link"}>Mode paysage</span>
          </div>
        </div>
      </div>
      <BottomButtons {...props}/>
    </div>
  );
}

const Step2 = (props) => {
  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div style={{display: 'flex', flexDirection: 'column', margin: 'auto', width: '70%'}}>
        <CardTypeGrid {...props}/>
      </div>
      <BottomButtons isCardBaseState={true} {...props} />
    </div>
  );
}

const Step3 = (props) => {
  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div style={{display: 'flex', flexDirection: 'column', margin: 'auto', width: '70%'}}>
        <h2 className=''>Import du logo</h2>
        <h5 style={{marginBottom: 40, marginTop: 0}}><b>ATTENTION:</b> pour les cartes noires et bois, votre logo sera monochrome.</h5>
        <ImportCardLogo {...props} face='front'/>

        <CardLogoSizeSlider {...props} sliderDefaultValue='400' type="front"/>
        <FormGroup aria-label="position" row>
          <FormControlLabel
            style={{display: 'none'}}
            control={<Checkbox color="primary" />}
            label="Je souhaite que mon logo soit sur fond transparent"
            labelPlacement="end"
            checked={props.transparentLogo}
            onChange={(e) => props.onChangeTransparentLogo()}
          />
        </FormGroup>
      </div>
      <BottomButtons {...props} />
    </div>
  );
}

const Step4 = (props) => {
  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div style={{display: 'flex', flexDirection: 'column', margin: 'auto', width: '70%'}}>
        <CardTextureComponent {...props}/>
      </div>
      <BottomButtons {...props} />
    </div>
  );
}

const Step5 = (props) => {
  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div style={{display: 'flex', flexDirection: 'column', margin: 'auto', width: '70%'}}>
        <CardColorComponent {...props}/>
      </div>
      <BottomButtons {...props} />
    </div>
  );
}

const Step6 = (props) => {
  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div style={{display: 'flex', flexDirection: 'column', margin: 'auto', width: '100%'}}>
        <InformationComponent {...props}/>
      </div>
      <BottomButtons {...props} />
    </div>
  );
}

const Step7 = (props) => {
  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <Validation {...props} />
    </div>
  );
}


const StateHandler = (props) => {
  if (props.commandStep === 0) {
    return <Step0 {...props}/>
  }
  else if (props.commandStep === 1) {
    return <Step1 {...props}/>
  }
  else if (props.commandStep === 2) {
    return <Step2 {...props}/>
  }
  else if (props.commandStep === 3) {
    return <Step3 {...props}/>
  }
  else if (props.commandStep === 4) {
    return <Step4 {...props}/>
  }
  else if (props.commandStep === 5) {
    return <Step5 {...props}/>
  }
  else if (props.commandStep === 6) {
    return <Step6 {...props}/>
  }
  else if (props.commandStep === 7) {
    return <Step7 {...props}/>
  }
}

export default StateHandler;

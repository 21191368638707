import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import ImportCardLogo from '../fileExplorer/importLogoFileExplorer.js';
import CardLogoSizeSliderBack from '../sliders/cardLogoSizeSliderForBack.js';
import CheckboxRow from '../rows/checkboxRow.js';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import '../components.css';
import { RiWindowsFill } from 'react-icons/ri';

const PersonalInformationComponent = (props) => {

  return (
    <div style={{marginBottom: '1em'}} className="c-formulaire_field_outlined">
      {!props.isCommandMultiple ? (
      <>
        <h2 style={{marginBottom: '1.5em', width: "120%"}}>Renseignez vos informations personnelles</h2>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div
            style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: ""}}>
            <TextField style={{marginBottom: 10, width: '48%'}} label='Prénom*' value={props.cardFirstName} onChange={(e) => props.handleInputChangeForCardContact('cardFirstName', e.target.value)} variant="outlined"/>
            <TextField style={{marginBottom: 10, width: '48%'}} label='Nom*' value={props.cardlastName} onChange={(e) => props.handleInputChangeForCardContact('cardlastName', e.target.value)} variant="outlined" />
          </div>
          <div
            style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: ""}}>
            <TextField style={{marginBottom: 10, width: '48%'}} label='Poste*' value={props.cardJobTitle} onChange={(e) => props.handleInputChangeForCardContact('cardJobTitle', e.target.value)} variant="outlined" />
            <TextField className='informationComponent' style={{marginBottom: '1.5em', width: '48%'}} label='Téléphone*' value={props.cardPhoneNumber} onChange={(e) => props.handleInputChangeForCardContact('cardPhoneNumber', e.target.value)} variant="outlined" />
          </div>
        </div>
      </>
      ) : (
        <>
          <h2 style={{width: "120%"}}>Paramétrages supplémentaires</h2>
          <p style={{marginBottom: '', width: "120%"}}>Données indicatives : les informations seront remplacées par les données fournis dans le fichier collaborateur.</p>
        </>
      )}
      <div style={{marginTop: 10, marginBottom: 50}}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Alignement du texte</FormLabel>
          <FormGroup aria-label="position" row>
            <FormControlLabel
              control={<Checkbox color="primary" />}
              label="Gauche"
              labelPlacement="start"
              checked={props.alignText === "Gauche" ? true : false}
              onChange={() => props.handleTextAlign('Gauche')}
            />
            <FormControlLabel
              value="Centré"
              control={<Checkbox color="primary" />}
              label="Centré"
              labelPlacement="start"
              checked={props.alignText === "Centré" ? true : false}
              onChange={() => props.handleTextAlign('Centré')}
            />
            <FormControlLabel
              value="Droite"
              control={<Checkbox color="primary" />}
              label="Droite"
              labelPlacement="start"
              checked={props.alignText === "Droite" ? true : false}
              onChange={() => props.handleTextAlign('Droite')}
            />
          </FormGroup>
        </FormControl>
      </div>
      <CardLogoSizeSliderBack {...props} sliderDefaultValue='100' type="back"/>
    </div>
  );
}

export default PersonalInformationComponent;

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Modal from 'react-awesome-modal';
import TextField from '@material-ui/core/TextField';
import Container from "@material-ui/core/Container";
import SimpleButton from "../buttons/loginButtonNew";
import TextButton from "../buttons/textButton";
import SnackBar from "../snackbars/snackbar";
import Lottie from "react-lottie";

const ConnexionModalAfterCommand = (props) => {

  const [pwd, setPwd] = useState('');
  const [mail, setMail] = useState('');

  return (
    <Modal
      visible={props.isModalVisible}
      effect="fadeInUp"
      style={{height: 'fit-content'}}
      onClickAway={props.handleModalState}
    >
      <div
        className='container c-formulaire_field_outlined'
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          borderRadius: "15px",
          backgroundColor: 'white',
          maxWidth: '30em'
        }}
      >
        <img src="https://www.wemet.fr/img/contenu/wemet/presentation-wecard.jpg" alt=""
             style={{width: "8em", height: "auto", marginTop: "-3em", borderRadius: '50%'}}/>
        {/*Screen title*/}
        <h1 id='appTitle' style={{color: "#003737", textAlign: 'center'}}>Connexion </h1>
        {/*Login form*/}
        <form style={{
          display: 'flex',
          flexDirection: 'column',
          width: '80%',
          minWidth: '20em',
          marginBottom: '1em'
        }}>
          {/*Email textfield*/}
          <TextField variant="outlined" value={mail} onChange={(e) => {
            setMail(e.target.value)
            if (typeof props.onchangeMail === 'function') {
              props.onchangeMail(e.target.value);
            }
          }}
                     label='Email*'/>
          {/*Password textfield*/}
          <TextField variant="outlined" value={pwd} type='password'
                     onChange={(e) => {
                       setPwd(e.target.value)
                       if (typeof props.onchangePwd === 'function') {
                         props.onchangePwd(e.target.value);
                       }
                     }} label='Mot de passe*'/>
        </form>
        {/*Buttons div*/}
        <div
          style={{display: 'flex', flexDirection: 'column', width: '50%', minWidth: '10em', maxWidth: '15em', marginBottom: 10}}>
          {/*Login button*/}
          <SimpleButton onClick={props.loginButtonClicked} isColorPrimary={true} buttonName='Connexion'/>
          <span style={{marginBottom: "0.5em"}}/>
          {/*Register button*/}
          <SimpleButton onClick={props.registerButtonClicked} isColorPrimary={false}
                        buttonName='Créer un compte'/>
          <span style={{marginBottom: "0.5em"}}/>
          {/*Forgot password button*/}
          <div onClick={props.forgotPasswordButtonClicked} className="pointer" style={{width: "100%", textAlign: 'center', marginBottom: 20}}>
              <span className="c-article__link">Mot de passe oublié ?</span>
            </div>
        </div>
        {
          //spinning loader
          props.isLoaderVisible ?
            <div className='loader'><Lottie options={props.lottieSpinnerOption} isClickToPauseDisabled={true}/>
            </div> : null
        }
      </div>
    </Modal>
  );
}
export default ConnexionModalAfterCommand;

ConnexionModalAfterCommand.propTypes = {};

import React, { useState } from 'react';
import Modal from 'react-awesome-modal';
import Button from '@material-ui/core/Button';
import * as Services from '../../services/apiServices';
import {loadStripe} from "@stripe/stripe-js";

 const OrderUpdate = (props) => {
  const { handleOrderUpdateModalState, getOrderCSV } = props;
  const [csvResult, setCsvResult] = useState(null);
  const [csvError, setCsvError] = useState(false);

  const proceedXLSX = async (orderId) => {
    setCsvResult(null);
    setCsvError(false);
    var file = document.getElementById('csvInputFile').files[0];
    const result = await Services.orderCompareCSV(orderId, file);
    if (result.success && result.csv.length > 0) {
      setCsvResult(result.csv);
    } else {
      setCsvError(true)
    }
  }

  const proceedOrder = async (orderId) => {
    var file = document.getElementById('csvInputFile').files[0];
    const result = await Services.orderDuplicateWithCSV(orderId, file);
    if (result.success && result.sessionCheckout) {
      const stripe = await loadStripe(result.stripe_pk);
      return stripe.redirectToCheckout({ sessionId: result.sessionCheckout });
    }
    if (result.success) {
      return window.location.reload();
    }
  }

  return (
    <Modal
      style={{overflowY: "scroll"}}
      visible={props.isOrderUpdateModalVisible}
      width="700px"
      effect="fadeInUp"
      onClickAway={() => handleOrderUpdateModalState()}
    >
      <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center', marginLeft: 4, padding: 20}}>
        <h1 style={{marginBottom: 0}}>Mettre à jour votre fichier</h1>
        <p style={{marginBottom: '1em', textAlign: 'left'}}>Pour mettre à jour les informations de votre WeCard, téléchargez le fichier .xlsx, modifiez la ou les informations concernées, puis renvoyez votre fichier en cliquant sur le bouton ci-dessous :</p>
        <p style={{marginBottom: '2em', textAlign: 'left', fontStyle: "italic"}}>À noter : vous devez renommer votre fichier avant de l'envoyer.</p>
        <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between'}}>
          <div onClick={() => getOrderCSV(props.selectedOrder)} className="hoverButtonLogin pointer" style={{width: "48%"}}>
            <span className="hoverButtonLoginText">Télécharger le fichier (.xlsx)</span>
          </div>
          <Button
            className="hoverButtonLogin pointer" style={{borderRadius: '35px', padding: '0', fontFamily: 'tradeGothic', width: "48%"}}
            variant="contained"
            component="label"
            disableRipple="true"
            disableFocusRipple="true"
            disableElevation="true"
          >
            Envoyer mon fichier (.xlsx)
            <input
              type="file"
              hidden
              id="csvInputFile"
              onChange={(event)=> {
                proceedXLSX(props.selectedOrder)
              }}
            />
          </Button>
        </div>
        { csvError &&
          <h5 style={{width: "100%", color: 'red'}}>Aucun contact à modifier</h5>
        }
        { csvResult !== null &&
          <div style={{marginTop: 40}}>
            <h5 style={{width: "100%", color: 'green', margin: 0}}>{csvResult.length} contact(s) modifié(s)</h5>
            <h5 style={{width: "100%", margin: 0}}>{csvResult.map(function(elem){ return elem[0] + " " + elem[1]; }).join(",")}</h5>

            <div onClick={() => proceedOrder(props.selectedOrder)} className="hoverButtonLogin pointer" style={{width: "150px", marginLeft: 'auto', marginRight: 'auto', marginTop: 10}}>
              <span className="hoverButtonLoginText">Commander</span>
            </div>
          </div>
        }
      </div>
    </Modal>
  );
}
export default OrderUpdate;

OrderUpdate.propTypes = {

};

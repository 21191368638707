import React from 'react';
import ReactDOM from 'react-dom';
import Container from '@material-ui/core/Container';
import {withRouter} from "react-router-dom";
import './screens.css';
import BgImage from "../assets/images/bg-acc.webp";
import Button from '@material-ui/core/Button';

class SuccessResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
  }


  render() {
    return (
        <div style={{
          height: '100vh',
          backgroundImage: `url(${BgImage})`,
          backgroundPosition: "20% 22%",
          backgroundSize: "cover",
          display: 'flex',
        }}>
          <div style={{margin: "auto", width: 'auto', height: 'auto', backgroundColor: "white", padding: 20}}>
            <h2 style={{display: "flex", textAlign: "center", justifyContent: "center"}}>Modification de mot de passe effectuée avec succès</h2>
            <div style={{display: "flex", justifyContent: "flex-end"}}>
              <Button
                variant="contained"
                onClick={() => window.location = "https://wemet-fo.slapp.me/connexion"}
                style={{backgroundColor: '#003732', width: '20em', margin: 3}}
              >
              <span style={{color: 'white'}}>Retour</span>
              </Button>
            </div>
          </div>
        </div>
    );
  }
}

export default withRouter(SuccessResetPassword);

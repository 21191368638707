import React from 'react';
import ReactDOM from 'react-dom';
import Login from './login.js';
import * as Services from '../services/apiServices.js';
import * as UserService from '../services/userServices.js';
import Container from '@material-ui/core/Container';
import AppBar from '../components/appBar/homeAppBar.js';
import LargeProfileGrid from '../components/grids/largeProfileGrid.js';
import SmallProfileGrid from '../components/grids/smallProfileGrid.js';
import Modal from '../components/modals/resetPasswordModal';
import {withRouter} from "react-router-dom";
import './screens.css';
import Footer from "../components/footer/footer";
import SnackBar from '../components/snackbars/snackbar.js';
import TextField from "@material-ui/core/TextField";
import FormCheckout from "../components/stripe/FormCheckout";
import MyCards from "../components/stripe/MyCards";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      me: null,
      email: '',
      firstName: '',
      lastName: '',
      company: '',
      address: '',
      additionalAddress: '',
      phoneNumber: '',
      city: '',
      postalCode: '',
      isUserCommercial: false,
      companyCode: '',
      screenWidth: 0,
      isUserConnected: false,
      cardNumber: '',
      cardExpiration: '',
      cardExpirationSaveLength: 0,
      cryptogram: '',
      isResetPasswordModalVisible: false,
      isSnackbarVisible: false,
      snackbarSeverity: '',
      snackbarMessage: '',
      cards: [],
    };
    this._containerRef = React.createRef();
  }

  componentDidMount() {
    this._getMyCards();
    this._getMe();
    const isUserConnected = localStorage.getItem("isUserConnected");
    if (isUserConnected === "true") {
      this.setState({isUserConnected: true});
      window.addEventListener('resize', this._updateDimension);
      this.setState({screenWidth: window.innerWidth});
    } else {
      this.props.history.push('/connexion');
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._updateDimension);
  }

  componentDidUpdate() {
  }

  _menuClicked = () => {
  }

  _updateDimension = () => {
    this.setState({screenWidth: window.innerWidth});
  }

  _changeUserCommercialState = () => {
    const currentState = this.state.isUserCommercial;
    this.setState({isUserCommercial: !currentState});
  }

  _handleResetPasswordModalState = () => {
    const currentState = this.state.isResetPasswordModalVisible;
    this.setState({isResetPasswordModalVisible: !currentState});
  }

  //function called to open or close snackbar
  _handleSnackbarState = () => {
    const currentState = this.state.isSnackbarVisible;
    this.setState({isSnackbarVisible: !currentState});
  }

  _changePassword = async (oldPwd, newPwd) => {
    const res = await Services.changePassword(JSON.stringify({currentPassword: oldPwd, newPassword: newPwd}));

    if (res.statusCode === 500) {
      return "Erreur interne, réessayez plus tard";
    } else if (res.statusCode === 400) {
      return "Veuillez remplir tous les champs";
    } else if (!res.success) {
      return res.message;
    } else if (res.success) {
      setTimeout(() => {
        this._handleResetPasswordModalState();
      }, 1000)
      return res.message;
    }
  }

  _handleInputChange = (stateName, e) => {
    //new value for stateName
    let stateNewValue = e.target.value;
    //new value size for stateName
    const stateNewValueLenght = stateNewValue.length;
    //handle credit card expiration
    if (stateName === 'cardExpiration') {
      //month ok, add separation : ' / '
      if (stateNewValue.length === 2 && stateNewValue.length > this.state.cardExpirationSaveLength) {
        stateNewValue += ' / ';
      }
      //user remove year, must remove separation : ' / '
      else if (stateNewValue.length === 5 && stateNewValue.length < this.state.cardExpirationSaveLength) {
        stateNewValue = stateNewValue.replace(' / ', '');
      }
    }
    //save new input state
    this.setState({[stateName]: stateNewValue});
    //save new card expiration input size
    this.setState({cardExpirationSaveLength: stateNewValueLenght});
  }

  _getMyCards = async () => {
    const res = await Services.getMyCards();

    //console.log(res)
    if (res._values) {
      let cards = []
      res._values.data.forEach(i => {
        cards.push(i._values)
      });
      //console.log(cards)
      this.setState({cards: cards})
    }
  }

  _getMe = async () => {
    const res = await Services.getMe();

    if (res) {
      UserService.setUser(res);
      this.setState({me: res})
      this.setState({
        email: res.account.email,
        firstName: res.account.firstName,
        lastName: res.account.lastName,
        address: res.account.address1,
        additionalAddress: res.account.address2,
        phoneNumber: res.account.phone,
        city: res.city,
        postalCode: res.zipCode,
      })
    }
    if (res.account) {
      UserService.setAccount(res.account);
    }
  }

  _saveUser = async () => {
    const res = await Services.updateProfile(JSON.stringify({
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phone: this.state.phoneNumber,
      address1: this.state.address,
      address2: this.state.additionalAddress,
      city: this.state.city,
      zipCode: this.state.postalCode,
    }));

    if (res.statusCode === 500) {
      this.setState({snackbarMessage: 'Erreur Interne', snackbarSeverity: 'error'})
      this._handleSnackbarState();
    } else if (res.statusCode === 400) {
      this.setState({
        snackbarMessage: 'Veuillez remplir tous les champs',
        snackbarSeverity: 'error',
        isSnackbarVisible: true
      })
    } else if (!res.success) {
      this.setState({snackbarMessage: res.message, snackbarSeverity: 'error', isSnackbarVisible: true})
    } else if (res.success) {
      this.setState({snackbarMessage: res.message, snackbarSeverity: 'success', isSnackbarVisible: true})
    }
  }

  _deleteCard = async (token) => {
    const res = await Services.deleteCard(JSON.stringify({cardID: token}));
    if (res.statusCode === 500) {
      this.setState({snackbarMessage: 'Erreur Interne', snackbarSeverity: 'error', isSnackbarVisible: true})
    } else if (res.statusCode === 400) {
      this.setState({
        snackbarMessage: 'Tous les paramètres ne sont pas transmis',
        snackbarSeverity: 'error',
        isSnackbarVisible: true
      })
    } else if (res) {
      this.setState({snackbarMessage: "Carte supprimée", snackbarSeverity: 'success', isSnackbarVisible: true})
      this._getMyCards();
    }
  }
  _addCard = async (token) => {
    const res = await Services.addCard(JSON.stringify({card: token}));
    if (res.statusCode === 500) {
      this.setState({snackbarMessage: 'Erreur Interne', snackbarSeverity: 'error', isSnackbarVisible: true})
    } else if (res.statusCode === 400) {
      this.setState({
        snackbarMessage: 'Tous les champs ne sont pas envoyés',
        snackbarSeverity: 'error',
        isSnackbarVisible: true
      })
    } else if (res) {
      this.setState({snackbarMessage: 'Carte ajoutée', snackbarSeverity: 'success', isSnackbarVisible: true})
      this._getMyCards();
    }
  }


    render() {
    return (
      <div>
        <div
          ref={this._containerRef}
          className='container c-formulaire_field_outlined'
          style={{flexDirection: 'column', position: "relative"}}
        >

          <AppBar {...this.state} menuClicked={this._menuClicked} />
          {this.state.screenWidth >= 500 && this.state.me !== null ?
            <LargeProfileGrid
              firstName={this.state.me.account.firstName}
              lastName={this.state.me.account.lastName}
              email={this.state.me.account.email}
              phoneNumber={this.state.me.account.phone}
              company={this.state.me.company?.name}
              companyCode={this.state.me.company?.code}
              address={this.state.me.address1}
              additionalAddress={this.state.me.address2}
              postalCode={this.state.me.zipCode}
              city={this.state.me.city}
              cardNumber={this.state.cardNumber}
              cardExpiration={this.state.cardExpiration}
              cryptogram={this.state.cryptogram}
              screenWidth={window.innerWidth}
              isUserCommercial={this.state.isUserCommercial}
              changeUserCommercialState={this._changeUserCommercialState}
              isResetPasswordModalVisible={this.state.isResetPasswordModalVisible}
              handleResetPasswordModalState={this._handleResetPasswordModalState}
              saveUser={this._saveUser}
              handleInputChange={this._handleInputChange}
            />
            :
            <SmallProfileGrid
              firstName={this.state.firstName}
              lastName={this.state.lastName}
              email={this.state.email}
              phoneNumber={this.state.phoneNumber}
              companyCode={this.state.companyCode}
              company={this.state.company}
              address={this.state.address}
              additionalAddress={this.state.additionalAddress}
              postalCode={this.state.postalCode}
              city={this.state.city}
              cardNumber={this.state.cardNumber}
              cardExpiration={this.state.cardExpiration}
              cryptogram={this.state.cryptogram}
              screenWidth={window.innerWidth}
              isUserCommercial={this.state.isUserCommercial}
              changeUserCommercialState={this._changeUserCommercialState}
              handleInputChange={this._handleInputChange}
            />
          }

          <Modal
            isResetPasswordModalVisible={this.state.isResetPasswordModalVisible}
            handleResetPasswordModalState={this._handleResetPasswordModalState}
            sendVerifyPassword={this._changePassword}
          />

          {
            //alert snackbar
            this.state.isSnackbarVisible ?
              <SnackBar handleClose={this._handleSnackbarState}
                        customStyle={{bottom: 10, left: 10, zIndex: 2021, position: 'fixed'}}
                        severity={this.state.snackbarSeverity}
                        message={this.state.snackbarMessage}/> : null
          }

        </div>

        {/*<div className='container' style={{flexDirection: 'column', position: "relative", height: '300px'}}>*/}
        {/*  <h2 id='subtitle'>Ajouter une carte</h2>*/}
        {/*  <FormCheckout btnLabel={'Ajouter'} addAction={(cardId) => this._addCard(cardId)} showName={false} showEmail={false} showPhone={false} showCardNumber={true}*/}
        {/*                email={this.state.email} phone={this.state.phoneNumber} name={this.state.firstName}/>*/}
        {/*</div>*/}

        {/*<div className='container'*/}
        {/*     style={{flexDirection: 'column', position: "relative"}}>*/}
        {/*  <MyCards cardData={this.state.cards} deleteCard={(item) => this._deleteCard(item)}/>*/}
        {/*</div>*/}

        <Footer/>
      </div>
    );
  }
}

export default withRouter(Profile);

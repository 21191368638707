import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Modal from 'react-awesome-modal';


 const FileExplorerModal = (props) => {
  return (
    <Modal
      visible={props.isModalVisible}
      width="700px"
      height="600px"
      effect="fadeInUp"
      style={{height: 'fit-content'}}
      onClickAway={() => props.handleQrcodeModal()}
    >
      <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center', padding: 20}}>
        <h2 style={{fontSize: '1.5em', marginBottom: 40, textAlign: "center"}}>Pour importer une de vos images personnelles, scannez le Qrcode suivant</h2>
        <div style={{marginLeft: 'auto', marginRight: 'auto'}}>
          <div style={{width: 250, height: 250, border: "1px solid black"}}>
            <img style={{backgroundColor: 'black', color: 'white', width: 250, height: 250}} src={"data:image/png;base64," +  props.qrCode} />
          </div>
        </div>
        <p style={{marginTop: 40, textAlign: "left"}}>Une fois votre fichier envoyé sur notre serveur, vous pouvez le récupérer en cliquant sur le bouton suivant</p>
        <Button
          variant="contained"
          onClick={() => props.getUploadedFile()}
          style={{backgroundColor: '#003732'}}
        >
        <span style={{color: 'white'}}>Récupérer l'image</span>
        </Button>
      </div>
    </Modal>
  );
}
export default FileExplorerModal;

FileExplorerModal.propTypes = {

};

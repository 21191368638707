import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Modal from 'react-awesome-modal';

const getBackground = (url) => {
  return "url(" + url + ') 0 0 / contain no-repeat';
}

 const ImageBankModal = (props) => {
  return (
    <Modal
      visible={props.isModalVisible}
      width="700px"
      height="700px"
      effect="fadeInUp"
      style={{height: 'fit-content'}}
      onClickAway={() => props.handleImageBankModal()}
    >
      <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center', paddingLeft: 20, paddingRight: 20, paddingTop: 20}}>
        <h1 style={{fontSize: '1.5em', marginBottom: 20, textAlign: "center"}}>Choisissez dans notre banque d'image</h1>
        <div className="c-formulaire_field_outlined" style={{display: "flex", flexDirection: "column", marginLeft: 52, marginBottom: 20}}>
          <h2 style={{fontSize: '1em', textAlign: "left"}}>Rechercher une image</h2>
          <TextField style={{marginBottom: 10, width: '48%'}} value={props.searchValue} onChange={(e) => props.handleInputChangeForSearchIconModal(e.target.value)} variant="outlined" />
        </div>
        <div style={{display: "flex", flexDirection: 'row', flexWrap: 'wrap', justifyContent: "center",  overflowY: 'scroll', height: "auto", maxHeight: 430}}>
        {
          props.imageBankArray.map((tile, index) => (
            <div onClick={() => props.handleLogoFromImageBank(tile)} style={{width: 100, height: 100, borderColor: 'black', borderWidth: "1px", borderStyle: "solid", margin: 5, background: getBackground(tile)}} />
          ))
        }
        </div>
      </div>
    </Modal>
  );
}
export default ImageBankModal;

ImageBankModal.propTypes = {

};

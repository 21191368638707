import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import '../components.css';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
  },
  gridList: {
    width: '100%',
    height: 'auto',
  },
  gridListMin: {
    height: 100,
  },
}));

 const tileData = [
   {
     color: '#003939',
   },
   {
     color: '#8558b7',
   },
   {
     color: '#241cc4',
   },
   {
     color: '#4bd4ea',
   },
   {
     color: '#96be39',
   },
   {
     color: '#fccc04',
   },
   {
     color: '#f7a30d',
   },
   {
    color: '#FFFFFF',
   },
   {
     color: '#fc5c04',
   },
   {
     color: '#d91612',
   },
   {
     color: '#918e8e',
   },
   {
     color: '#000000',
   }
 ];

const ColorGrid = (props) => {
  const classes = useStyles();
  const [reset, setReset] = useState(false);

  useEffect(() => {
    if (props.userTextureFile == null && props.backgroundColor === 'white' && reset === true) {
      props.colorClicked(0, tileData[0].color, tileData[0].color);
    }
  }, [reset])

  if (props.userTextureFile == null && props.backgroundColor === 'white' && reset === false) {
    setReset(true);
  }
  return (
    props.screenWidth > 1000 ?
    <div className={classes.root}>
      <GridList cellHeight={100} className={classes.gridList} cols={3}>
        {tileData.map((tile, index) => (
          <GridListTile key={index} cols={1} style={{display: props.backgroundColor === "white" && props.userTextureFile == null && tile.color === "#FFFFFF" ? "none" : 'flex', marginBottom: -20}}>
            <div onClick={() => props.colorClicked(index, tileData[index].color, tile.color)} id='colorGridItem' className="pointer"
              style={props.selectedColorIndex === index ? {borderColor: 'red', borderWidth: '3px', backgroundColor: tile.color} : {borderColor: tile.color, backgroundColor: tile.color}}
            />
          </GridListTile>
        ))}
      </GridList>
    </div>
    :
    <div className={classes.root}>
      <GridList cellHeight="auto" className={classes.gridListMin} cols={2}>
        {tileData.map((tile, index) => (
          <GridListTile key={index} style={{display: props.backgroundColor === "white" && props.userTextureFile == null && tile.color === "#FFFFFF" ? "none" : 'flex'}}>
            <div onClick={() => props.colorClicked(index, tileData[index].color, tile.color)} id='colorGridItem' className="pointer"
              style={props.selectedColorIndex === index ? {borderColor: 'red', borderWidth: '3px', backgroundColor: tile.color, width: 40, height: 40} : {borderColor: tile.color, backgroundColor: tile.color, width: 40, height: 40}}
            />
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
}

export default ColorGrid;

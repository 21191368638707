import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Modal from 'react-awesome-modal';
import CloseModalButton from '../buttons/closeModalButton.js';
import EditPasswordModalButton from '../buttons/editPasswordModalButton';
import TextField from '@material-ui/core/TextField';

const ResetPasswordModal = (props) => {

  const [message, setMessage] = useState('');
  const [pwd, setPwd] = useState('');
  const [newPwd, setNewPwd] = useState('');
  return (
    <Modal
      visible={props.isResetPasswordModalVisible}
      width="60%"
      effect="fadeInUp"
      style={{height: 'fit-content'}}
      onClickAway={() => props.handleResetPasswordModalState()}
    >
      <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center', padding: '30px 40px'}} className="c-formulaire_field_outlined">
        <h1 style={{textAlign: 'center'}}>Changement de mot de passe</h1>
        <TextField style={{width: '70%', marginLeft: 'auto', marginRight: 'auto'}}
                   type={'password'} variant="outlined"
                   label='Ancien mot de passe*' onChange={(e) => {
                     setPwd(e.target.value)
        }}/>
        <TextField style={{width: '70%', marginLeft: 'auto', marginRight: 'auto'}}
                   type={'password'} variant="outlined"
                   label='Nouveau mot de passe*' onChange={(e) => {
                     setNewPwd(e.target.value)
        }}/>
        <p>{message}</p>
        <div onClick={() => {
          props.sendVerifyPassword(pwd, newPwd).then(msg => {
            //console.log(msg)
            setMessage(msg)
          })
        }} className="marginAuto hoverButtonLogin pointer"
             style={{marginTop: 40}}>
          <span className="hoverButtonLoginText">Modifier</span>
        </div>
      </div>
    </Modal>
  );
}
export default ResetPasswordModal;

ResetPasswordModal.propTypes = {
  isResetPasswordModalVisible: PropTypes.bool,
  handleResetPasswordModalState: PropTypes.func,
  sendVerifyPassword: PropTypes.func,
};

import React from 'react';
import {makeStyles, createMuiTheme} from '@material-ui/core/styles';
import {Animated} from "react-animated-css";
import {useHistory} from "react-router-dom";
import './homeAppBar.css';

export default function TemporaryDrawer(props) {
  const classes = useStyles();
  const history = useHistory();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [isVisible, setIsVisible] = React.useState(false);
  const [btnClassnames, setBtnClassnames] = React.useState('btn');
  const [navClassnames, setNavClassnames] = React.useState('');
  const [logoOpacity, setLogoOpacity] = React.useState(1);
  const [menuClassNames, setMenuClassNames] = React.useState('p-menu');

  const handleLogout = () => {
    localStorage.setItem("isUserConnected", "false");
    localStorage.setItem("user", null);
    localStorage.setItem("account", null);
    localStorage.setItem("token", null);
    window.location = "http://wemet.fr";
  }

  const disableScroll = () => {
    // Get the current page scroll position
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

    // if any scroll is attempted, set this to the previous value
    window.onscroll = function () {
      window.scrollTo(scrollLeft, scrollTop);
    };
  }

  const enableScroll = () => {
    window.onscroll = function () {
    };
  }

  const changeOpen = () => {
    if (btnClassnames.includes('ouvert')) {
      setBtnClassnames(btnClassnames.replace('ouvert', ''));
      setLogoOpacity(1);
      setNavClassnames('');
      setMenuClassNames('p-menu');
      setIsVisible(false);
      enableScroll();
    } else {
      let tmp = btnClassnames
      setBtnClassnames(tmp += " ouvert");
      setLogoOpacity(0);
      setNavClassnames('expanded');
      setMenuClassNames('p-menu ouvert');
      setIsVisible(true);
      disableScroll();
    }
  }

  return (
    <div className='navContainer'>
      <div className={classes.container}>
        <nav className={navClassnames} style={{
          width: "81.25vw",
        }}>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <a href="" title="La carte de visite connectée"
               style={{opacity: logoOpacity, zIndex: 1000}}>
              <img className={classes.logo} src="https://www.wemet.fr/img/logo-wemet.svg" alt="Logo WEMET" onClick={() => history.push('/')}/>
            </a>
          </div>
        </nav>
      </div>
    </div>
  );
}


const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  container: {
    // width: '100vw',
    // width: '100%',
    // backgroundColor: 'white',
    // margin: '0 auto',
    // padding: "26px 8.75vw",

  },
  nav: {},
  logo: {
    width: "11rem",
    display: "inline-block",
    pointerEvents: "normal",
    transition: "all .3s ease",
  },
  img: {
    width: "100%",
    height: "auto",
  }
  ,
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#FFFFFF'
    },
    secondary: {
      main: '#FFFFFF'
    },
  },
});

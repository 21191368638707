import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Modal from 'react-awesome-modal';
import TextField from '@material-ui/core/TextField';
import Container from "@material-ui/core/Container";
import SimpleButton from "../buttons/loginButtonNew";
import TextButton from "../buttons/textButton";
import SnackBar from "../snackbars/snackbar";
import Lottie from "react-lottie";
import RegisterComponent from "../register/register";

const RegisterModal = (props) => {

  const _redirectAfterSuccess = () => {
      props.handleModalState();
  }

  let hiddenStyle = {
    transform: 'translate3d(0px, 100px, 0px)', zIndex: -1, width: '0px', opacity: 0, height: '0px',overflow: 'hidden'
  }
  let shownStyle = {
    maxHeight: '90%', overflow: 'auto',transform: 'translate3d(0px, 0px, 0px)', zIndex: 10002, width: "60%", backgroundColor: 'rgb(255, 255, 255)', borderRadius: "5px", boxSizing: "borderbox", boxShadow: "rgba(0, 0, 0, 0.3) 0px 2px 4px", transition: "transform 500ms cubicbezier(0, 0, 0.25, 1) 0s, opacity 500ms cubicbezier(0, 0, 0.25, 1) 0s"
  };
  return (
    <div
      style={{
        position: "fixed", top: '0px', left: "0px", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: props.isModalVisible ? 10000 : -1
      }}>
      <div
        style={props.isModalVisible ? shownStyle : hiddenStyle}>
          <RegisterComponent redirectAfterSuccess={_redirectAfterSuccess} proceedToLogin={props.proceedToLogin}/>
      </div>
      <div
        onClick={props.handleModalState}
        style={{
          position: "fixed", top: "0px", left: "0px", width: "100%", height: '100%', backgroundColor: "rgba(0, 0, 0, 0.7)", zIndex: 10001, display: (props.isModalVisible ? 'initial' : 'none')
        }}></div>
    </div>
  )
  // return (
  //   <Modal
  //     visible={props.isModalVisible}
  //     effect="fadeInUp"
  //     width="60%"
  //     style={{height: 'fit-content', maxHeight: '80%', overflow: 'auto'}}
  //     onClickAway={props.handleModalState}
  //   >
  //   </Modal>
  // );
}
export default RegisterModal;

RegisterModal.propTypes = {};

import React from 'react';
import * as Services from '../../services/apiServices.js';
import * as Security from '../../services/securityServices.js';
import * as loader from '../../assets/animations/loader.json';
import Container from '@material-ui/core/Container';
import Lottie from 'react-lottie';
import TextField from '@material-ui/core/TextField';
import SimpleButton from '../../components/buttons/loginButtonNew.js';
import CheckboxRow from '../../components/rows/checkboxRow.js';
import SnackBar from '../../components/snackbars/snackbar.js';
import {withRouter} from "react-router-dom";
import '../../screens/screens.css';

//option used for spinning loader
const lottieSpinnerOption = {
  loop: true,
  autoplay: true,
  animationData: loader.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

//implementation of sleep function
const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

class RegisterComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      passwordConfirmation: '',
      firstName: '',
      lastName: '',
      company: '',
      address: '',
      additionalAddress: '',
      phoneNumber: '',
      isUserCommercial: false,
      postalCode: '',
      city: '',
      termsAndConditionsValidated: true,
      isSnackbarVisible: false,
      snackbarMessage: '',
      snackbarSeverity: '',
      screenWidth: '',
      screenHeight: '',
      isLoaderVisible: false,
      promoCode: '',
      promoCodeValid: null,
      accountLinkToken: '',
    };
  }

  componentDidMount() {
    this.setState({accountLinkToken: this.props.match.params.id}, function() {
      //console.log(this.state.accountLinkToken);
    });
  }

  _updateDimension = () => {
    this.setState({screenWidth: window.innerWidth});
    this.setState({screenHeight: window.innerHeight});
  }

  //function called to open or close snackbar
  _handleSnackbarState = () => {
    const currentState = this.state.isSnackbarVisible;
    this.setState({isSnackbarVisible: !currentState});
  }

  //called when user clicks on register button
  _validateButtonClicked = async () => {
    const isPromoValid = this.state.promoCode === null || this.state.promoCode === '' ? true : await this._getCheckPromoCode(this.state.promoCode);
    const isEmailValid = Security.isEmailFormatOk(this.state.email);
    const hasEmptyFields = this._checkForEmptyFields();
    if (!isPromoValid || !isEmailValid || !hasEmptyFields) {

      if (!hasEmptyFields) await this._handleSnackbarDisplay('fieldsKo');
      else if (!isEmailValid) await this._handleSnackbarDisplay('emailFormatKo');

      return;
    }

    //check password strongness
    /*if (!Security.isPasswordStrongEnough(this.state.password, this.state.passwordConfirmation)) {
      this._handleSnackbarDisplay('passwordStrongKo');
      return;
    }*/
    const data = {
      email: this.state.email,
      password: this.state.password,
      passwordConfirmation: this.state.passwordConfirmation,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      company: this.state.company,
      address: this.state.address,
      additionalAddress: this.state.additionalAddress,
      isUserCommercial: this.state.isUserCommercial,
      postalCode: this.state.postalCode,
      city: this.state.city,
      phone: this.state.phoneNumber,
      promoCode: this.state.promoCode,
      accountLinkToken: this.state.accountLinkToken
    };

    //this.setState({isLoaderVisible: true});
    const accountCreationSuccess = await Services.registerForExhibition(JSON.stringify(data));
    if (!accountCreationSuccess.success) {
      if (accountCreationSuccess.statusCode === 500) {
        this._handleSnackbarDisplay('registerKo');
      } else {
        this._handleSnackbarDisplay('registerKo', accountCreationSuccess?.message);
      }
    } else {
      //this._handleSnackbarDisplay('registerSuccess');
      const data = {
        id: accountCreationSuccess.id,
        commandToken: this.props.match.params.id
      }
      const res = await Services.validateCommandFromExhibition(JSON.stringify(data));
      if (!res.success) {
        this._handleSnackbarDisplay('commandKo');
      }
      else {
        this.props.history.push('/connexion');
        this.props.proceedToLogin({password: this.state.password, email: this.state.email})
      }
    }
  }

  //used to check empty fields, company and additionalAddress can be empty
  _checkForEmptyFields = () => {
    if (this.state.password === '' || this.state.passwordConfirmation === '' || this.state.email === '') {
      return false;
    }
    if (this.state.firstName === '' || this.state.lastName === '' || this.state.phoneNumber === '') {
      return false;
    }
    return true;
  }

  //display snackbar with specific message
  _handleSnackbarDisplay = async (alert, msg) => {
    //required fields are not filled
    if (alert === 'fieldsKo') {
      this.setState({snackbarSeverity: 'error'});
      this.setState({snackbarMessage: "Merci de remplir les champs précédés par une étoile."});
    }
    //email format Ko
    if (alert === 'emailFormatKo') {
      this.setState({snackbarSeverity: 'error'});
      this.setState({snackbarMessage: "Merci de renseigner un email valide"});
    }
    //password is not strong enough
    if (alert === 'passwordStrongKo') {
      this.setState({snackbarSeverity: 'error'});
      this.setState({snackbarMessage: "Votre mot de passe n'est pas assez sécurisé. Il doit faire au moins 12 charactères de long et contenir au moins un chiffre, une lettre en majuscule et un caractère spécial."});
    }
    //register user KO
    if (alert === 'registerKo') {
      this.setState({snackbarSeverity: 'error'});
      this.setState({snackbarMessage: msg ?? 'Une erreur est survenue lors de la création de votre compte, merci de réesayer plus tard'});
    }
    if (alert === 'registerSuccess') {
      this.setState({snackbarSeverity: 'success'});
      this.setState({snackbarMessage: 'Votre compte est maintenant créé !'});
    }
    if (alert === 'commandKo') {
      this.setState({snackbarSeverity: 'error'});
      this.setState({snackbarMessage: "Une erreur est survenue lors de la validation de votre commande"});
    }
    //display snackbar
    this._handleSnackbarState();
    await sleep(2500);
    this._handleSnackbarState();
  }

  //called to update user commercial checkbox state
  _handleCommercialCheckboxclicked = () => {
    const currentState = this.state.isUserCommercial;
    this.setState({isUserCommercial: !currentState});
  }

  //handle textfields change
  _handleInputChange = (stateName, e) => {
    this.setState({[stateName]: e.target.value});
  }

  _getCheckPromoCode = async (code) => {
    this.setState({promoCodeValid: null}, async () => {
      const promoCode = await Services.getCheckPromoCode(code);
      if (promoCode.success && promoCode.isAmbassador) {
        this.setState({promoCodeValid: true},
          () => {
            return this.state.promoCodeValid
          });
      } else {
        this.setState({promoCodeValid: false},
          () => {
            return this.state.promoCodeValid
          });
      }
    });
  }

  render() {
    return (
      <Container
        maxWidth='md'
        className='container c-formulaire_field_outlined'
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          backgroundColor: "white",
          // border: "1px solid black",
          paddingTop: 30,
          paddingBottom: 30,
          borderRadius: "15px",
          overflowY: 'scroll',
          height: 'fit-content'
        }}
      >
        {/*Screen title*/}
        <h1 style={{color: "#003939", textAlign: 'center'}}>Inscription</h1>
        {/*Registrer form*/}
        <form style={{display: 'flex', flexDirection: 'column', width: '80%', minWidth: '20em', marginBottom: '1em'}}>
          <div
            style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: "1.4em"}}>
            {/*Last name confirmation textfield*/}
            <TextField style={{width: '48%'}} variant="outlined"
                       onChange={(e) => this._handleInputChange('lastName', e)}
                       label='Nom*'/>
            {/*First name confirmation textfield*/}
            <TextField style={{width: '48%'}} variant="outlined"
                       onChange={(e) => this._handleInputChange('firstName', e)}
                       label='Prénom*'/>
          </div>

          {/*Email textfield*/}
          <TextField onChange={(e) => {
            this._handleInputChange('email', e)
          }} variant="outlined"
                     label='Email*'/>

          <div
            style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: "20px"}}>
            {/*Password textfield*/}
            <TextField style={{width: '48%'}} variant="outlined"
                       onChange={(e) => {
                         this._handleInputChange('password', e)
                       }}
                       label='Mot de passe*' type='password'/>
            {/*Password confirmation textfield*/}
            <TextField style={{width: '48%'}} variant="outlined"
                       onChange={(e) => this._handleInputChange('passwordConfirmation', e)}
                       label='Confirmation mot de passe*' type='password'/>
          </div>


          <div
            style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            {/*Company name confirmation textfield*/}
            <TextField style={{width: '48%'}} variant="outlined"
                       onChange={(e) => this._handleInputChange('company', e)}
                       label='Société'/>
            {/*Phone number textfield*/}
            <TextField style={{width: '48%'}} variant="outlined"
                       onChange={(e) => this._handleInputChange('phoneNumber', e)}
                       label='Téléphone*'/>
          </div>


          {/*Address name confirmation textfield*/}
          <TextField onChange={(e) => this._handleInputChange('address', e)} variant="outlined"
                     label='Adresse'/>
          <TextField onChange={(e) => this._handleInputChange('additionalAddress', e)} variant="outlined"
                     label="Complément d'adresse"/>
          <div
            style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            {/*Postal code textfield*/}
            <TextField style={{width: '48%'}} variant="outlined"
                       onChange={(e) => this._handleInputChange('postalCode', e)}
                       label='Code postal'/>
            {/*Additional address name confirmation textfield*/}
            <TextField style={{width: '48%'}} variant="outlined"
                       onChange={(e) => this._handleInputChange('city', e)}
                       label="Ville"/>
          </div>

          <h5 style={{marginBottom: 5}}>Vous avez un code ambassadeur ?</h5>
          <TextField style={{width: '100%'}} variant="outlined"
                     onChange={(e) => {
                       this._handleInputChange('promoCode', e)
                     }}
                     onFocus={(e) => this.setState({promoCodeValid: null})}
                     label="Code ambassadeur"/>
          {this.state.promoCodeValid === true &&
          <h5 style={{color: 'green', margin: 0}}>Ce code est valide</h5>
          }
          {this.state.promoCodeValid === false &&
          <h5 style={{color: 'red', margin: 0}}>Ce code est invalide</h5>
          }
        </form>
        <div style={{
          width: "80%",
          display: 'flex',
          justifyContent: 'flex-end',
        }}>
          <div
            style={{
              minWidth: '10em',
              maxWidth: '20em'
            }}>
            {/*Validate button*/}
            <SimpleButton onClick={this._validateButtonClicked} buttonName='Valider' primaryColor={true}/>
          </div>
        </div>
        {
          //alert snackbar
          this.state.isSnackbarVisible ?
            <SnackBar handleClose={this._handleSnackbarState} severity={this.state.snackbarSeverity}
                      message={this.state.snackbarMessage}/> : null
        }
        {
          this.state.isLoaderVisible ?
            <div className='loader'><Lottie options={lottieSpinnerOption} isClickToPauseDisabled={true}/></div> : null
        }
      </Container>
    );
  }
}

export default withRouter(RegisterComponent);

import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { AiOutlineArrowRight, AiOutlineArrowLeft } from 'react-icons/ai';

 const NewOrderBottomButtons = (props) => {
  return (
    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', wrap: "flex-wrap"}}>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: 5}}>
        <div className="hoverButton pointer" style={{width: 150}} onClick={() => {
          if (props.setCanGoToValidationState) {
            props.setCanGoToValidationState(false);
          }
          props.updateCommandStep('prev')
        }}>
          <p className="hoverButtonLoginText">Retour</p>
        </div>
      </div>
      {
        props.isCardBaseState !== true
        ?

          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: 5}}>
            <div className="hoverButton pointer" style={{width: 150}} onClick={() => {
              //console.log("props.commandStep",props.commandStep)
              //console.log("props.canGoToValidation",props.canGoToValidation)
              if (props.commandStep === 6 ) {
                if (props.canGoToValidation) {
                  props.saveBackCardFace()
                }
              }
              else {
                props.updateCommandStep('next')
              }
            }}>
              <p className="hoverButtonLoginText">Suivant</p>
            </div>
          </div>
        : props.selectedCardBaseIndex !== -1
        ?
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: 5}}>
          <div className="hoverButton pointer" style={{width: 150}} onClick={() => props.updateCommandStep('next')}>
            <p className="hoverButtonLoginText">Suivant</p>
          </div>
        </div>
        :
        <div className="" style={{width: 60, height: 60, border: "0px solid black", borderRadius: 30, position: "relative", marginRight: 5}}>
          <AiOutlineArrowRight className="arrow" style={{position: "absolute", top: "50%", left: "50%", fontSize: 0, transform: "translate(-50%, -50% )"}}/>
        </div>
      }

    </div>
  );
}
export default NewOrderBottomButtons;

NewOrderBottomButtons.propTypes = {

};

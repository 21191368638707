import React, { useState, useRef } from 'react';
import ImportTextureButton from '../buttons/importTextureButton.js';

const ImageUpload = (props) => {
  const [image, setImage] = useState('');
  const inputFile = useRef(null);

  /*const handleFileUpload = e => {
    const { files } = e.target;
    if (files && files.length) {
      const filename = files[0].name;
      console.log('name : ' + filename);
      var parts = filename.split('.');
      const fileType = parts[parts.length - 1];
      console.log('fileType : ', fileType);
      setImage(files[0]);
    }
  };*/

  const handleFileUpload = e => {
    const { files } = e.target;
    if (files && files.length) {
      const file = (window.URL || window.webkitURL).createObjectURL(files[0]);
      props.saveUserTextureFile(file);
    }
  };

  const onButtonClick = () => {
    inputFile.current.click();
  };

  return (
    <div>
      <input
        style={{ display: 'none' }}
        ref={inputFile}
        onChange={handleFileUpload}
        type='file'
      />
    </div>
  );
};

export default ImageUpload;
